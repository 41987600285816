@import '~@blueprintjs/core/lib/css/blueprint.css';
@import '~@blueprintjs/icons/lib/css/blueprint-icons.css';
@import '~@blueprintjs/datetime/lib/css/blueprint-datetime.css';
@import '~@blueprintjs/select/lib/css/blueprint-select.css';

/* @import url('https://fonts.googleapis.com/css?family=Cutive+Mono|Press+Start+2P|Special+Elite&display=swap');
@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Sans|Inconsolata|Karla|Noto+Sans|Old+Standard+TT|Raleway|Source+Sans+Pro&display=swap'); */
// @import url('https://fonts.googleapis.com/css?family=Karla|Russo+One|Merriweather+Sans:400,400i&display=swap');

// @import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans:ital,wght@0,300;0,400;1,300;1,400&display=swap');

// @import url('https://fonts.googleapis.com/css2?family=DM+Mono:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&family=IBM+Plex+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Work+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400;1,700&family=Inter:wght@400;500;700&display=swap');

// @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=SUSE:wght@100..800&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=SUSE:wght@100..800&display=swap');

@import url('react-datepicker/dist/react-datepicker.css');

@import './variablesOfStyle.scss';

body {
  margin: 0;
  padding: 0;
  font-family: 'Fira Sans', sans-serif;
  /* there should be a secret unlockable mode that uses "Press Start 2P", which is amazing */
  color: $mainFontColour;
  text-align: center;
  font-size: 12pt;
}

input,
button,
select {
  font-family: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5em;
}

.App-logo {
  /* animation: App-logo-spin infinite 20s; */
  height: 80px;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.gp-flex-grid {
  display: flex;
}

@media (max-width: 700px) {
  .gp-flex-grid {
    display: block;
  }
}

.gp-flex-grid-large {
  display: flex;
}

@media (max-width: 1200px) {
  .gp-flex-grid-large {
    display: block;
  }
}

.gp-flex-grid-col-1 {
  flex: 1;
}

.gp-flex-grid-col-2 {
  flex: 2;
}

.gp-flex-grid-col-3 {
  flex: 3;
}

.gp-flex-grid-col-4 {
  flex: 4;
}

/*------------------------------------------------------------------------------------------*/
/*-----------------Badges-------------------------------------------------------------------*/

.badge {
  background-color: red;
  color: white;
  border-radius: 3px;
  margin-left: 5px;
}

/*------------------------------------------------------------------------------------------*/
/*-----------------Markdown editor----------------------------------------------------------*/

.markdown-text {
  font-family: Menlo;
}

.markdown-editor {
  height: 95%;
}

.CodeMirror {
  height: 80%;
  z-index: 0;
}

.CodeMirror-code {
  text-align: left;
  z-index: 0;
}

.CodeMirror-line {
  text-align: left;
  z-index: 0;
}

/*------------------------------------------------------------------------------------------*/
/*-----------------Task animations----------------------------------------------------------*/

.gp-btn-anim-enter {
  opacity: 0;
}

.gp-btn-anim-enter-active {
  opacity: 1;
  transition: 'opacity 500ms ease-in';
}

.gp-btn-anim-exit {
  opacity: 1;
}

.gp-btn-anim-exit-active {
  opacity: 0;
  transition: 'opacity 500ms ease-in';
  background-color: 'salmon';
}

/*------------------------------------------------------------------------------------------*/
/*-----------------Overrides----------------------------------------------------------------*/

.DateInput_input {
  color: $mainFontColour;
  font-weight: 300;
}

.SingleDatePicker {
  height: 40px;
}

.SingleDatePickerInput {
  height: 40px;
}

.SingleDatePickerInput__withBorder {
  border: 1px solid gray;
  border-radius: 2px;
}

.DateInput {
  height: 35px;
}

.DateInput_input {
  height: 35px;
  font-size: 14px;
}

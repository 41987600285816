$mainBackground: rgb(214, 219, 219);

// text
$mainFontColour: rgb(105, 110, 110);

$statusBarBgColour: rgba(255, 255, 255, 0.8);
$statusBarColour: rgb(105, 110, 110);
$statusBarAccentColour: rgb(155, 167, 167);

$navbarBackground: rgb(214, 219, 219);
$navbarItemActiveBackground: rgb(142, 170, 170);
$navbarItemHoverBackground: rgb(157, 192, 192);

$taskBackgroundColour: white;
$taskHoverBackgroundColour: rgb(247, 247, 247);
$taskDraggingBackgroundColour: rgb(226, 226, 226);
$taskGroupDraggingOverBackgroundColour: rgb(190, 235, 241);

$navPomodoroClockRunningBgColour: rgba(217, 79, 69, 0.7);
$navPomodoroClockPausedBgColour: rgba(165, 79, 125, 0.3);

$navbarTopBgColour: rgba(244, 249, 249, 0.5);

$projectBoxAtTopBgColour: white;

// do we need the below?

$bmOverlay: rgba(0, 0, 0, 0.3);
